import { fetchGet, fetchPost } from '@/router/https'

export const getStoreDetail = function (params) {
  return fetchGet('/mallapp/goodsSpuFuNeng/shopInfoGoodsSpu', params)
}

// 商品详情
// export const commldityDetail = (id) => fetchGet('/mallapp/goodsSpuWelfare/' + id)
export const commldityDetail = (id) => fetchPost('/mallapp/goodsspu/goodsSpuDetails0109?id=' + id)

// 优惠券列表
export const getTickerList = (params) => fetchGet('/createrapp/coupon/getShopCoupon', params)

// 商品详情
export const ydCommldityDetail = (id) => fetchGet('/mallapp/goodsSpuCloud/' + id)

// 商品规格
export const getGuige = (id) => fetchGet('/mallapp/orderinfo/welfareBuy/' + id)

// 购物车
export const getCart = () => fetchGet('/mallapp/shoppingcart/myShoppingCart')

// 购物车
export const getCart1 = () => fetchGet('/mallapp/shoppingcart/myShoppingCart1')

// 侠客集市购物车
export const getYundianCart = () => fetchGet('/mallapp/shoppingcart/myCloudCart')

// 加入购物车
export const addCart = (data) => fetchPost('/mallapp/orderinfo/welfareBuyAgain', data)

// 删除商品
export const delCart = (data) => fetchPost('/mallapp/shoppingcart/del', data)

// 获取余额
export const getYve = () => fetchGet('/mallapp/orderinfo/getAccountMoney')

// 修改购物车数量
export const updateQuantity = (data) => fetchPost(`/mallapp/shoppingcart/updateSpuQuantity?cartId=${data.cartId}&quantity=${data.quantity}&isTick=${data.isTick}`,)

// 支付详情
export const zhifuInfo = (data, ticketId, payType) => fetchPost(`/mallapp/orderinfo/welfareOrderInfo?sysCouponUserId=${ticketId.sysCouponId}&shopCouponUserId=${ticketId.shopCouponId}&payType=${payType}`, data)

// 红尘坊提交订单
export const submit = (data, ticketId, idType, payType) => fetchPost(`/mallapp/orderinfo/welfareOrderCommit?sysCouponUserId=${ticketId.sysCouponId}&shopCouponUserId=${ticketId.shopCouponId}&advertisementId=${idType.advertisementId||''}&advertisementType=${idType.advertisementType||'0'}&payType=${payType}`, data)

// 侠客集市提交订单
export const ydSubmit = (data, ticketId, idType) => fetchPost(`/mallapp/orderinfo/cloudOrderCommit?sysCouponUserId=${ticketId.sysCouponId}&shopCouponUserId=${ticketId.shopCouponId}&advertisementId=${idType.advertisementId||''}&advertisementType=${idType.advertisementType||'0'}`, data)

// 红尘坊支付新汇付
// export const zhifu = (data) => fetchPost('/mallapp/orderinfo/AdaPayWelFareMallPay', data)

// 红尘坊支付老
// export const zhifu = (data) => fetchPost('/mallapp/orderinfo/unifiedOrderWelFareAll', data)

// 侠客集市支付新汇付
// export const ydZhifu = (data) => fetchPost('/mallapp/orderinfo/AdaPayCloudMallPay', data)

// 侠客集市支付老
// export const ydZhifu = (data) => fetchPost('/mallapp/orderinfo/unifiedOrderCloudAll', data)

// 红尘坊支付宝支付
export const zhifu = (data) => fetchPost('/mallapp/orderinfo/aliPayWelFareMallPay', data)

// 侠客集市支付
export const ydZhifu = (data) => fetchPost('/mallapp/orderinfo/aliPayCloudMallPay', data)

// 侠客集市/红尘坊是否开启金叶子支付
export const isHonemi = (params) => fetchGet('/mallapp/orderinfo/cloudAndWelfare', { params })

// 金米汇付
export const jinmiZhifu = (data) => fetchPost('/mallapp/orderinfo/AdaPayCloudMallPays', data)

// 退款详情
export const refundDetail = (params) => fetchGet('/mallapp/orderrefunds/applyForRefundDetails', params)

// 取消退款申请
export const cancalRefundDetail = (data) => fetchPost('/mallapp/orderrefunds/orderCancel', data)

// 红尘坊重新申请
export const chexiaoshenqing = (data) => fetchPost('/mallapp/orderrefunds/applyForRefund', data)

// 侠客集市重新申请
export const yuandianChexiaoshenqing = (data) => fetchPost('/mallapp/orderrefunds/YDApplyForRefund', data)

// 获取官方电话
export const getPhoneCode = (params) => fetchGet('/mallapp/appversion/serverPhone', params)
// 商品评价
export const goodsEvaluate = (data) => fetchPost('/mallapp/goodsappraises/getGoodsAppraises', data)
//获取店铺评分
export const shopEvaluate = (data) => fetchPost('/mallapp/goodsappraises/getShopScore', data)

// 兑换领取优惠券
export const duihuanTicket  = (data = {}) => fetchPost('/createrapp/coupon/exchangeCoupon?couponId=' + data.couponId)

//使用优惠券
export const useTicket = (data = {}) => fetchPost('/createrapp/coupon/useCoupon', data)

// 罗马商品详情
export const getLuoma = (params = {}) => fetchGet('/mallapp/orderfictitious/virtualGoodsDetails', params)

// 账户剩余
export const getUserMoney = () => fetchGet('/mallapp/orderfictitious/getAccount')

// 鬼市虚拟商品下单
export const getGuishiOrder = (data = {}) => fetchPost('/mallapp/orderfictitious/buyGoods', data)

// 获取用户驿马
export const getPersonPostHorse = () => fetchGet('/mallapp/riceuser/getPersonPostHorse')

export const getIsPostHorse = () => fetchPost('/mallapp/goodsSpuWelfare/getIsPostHorse')