import https from '@/router/https'
//获取售后订单的信息+申请退货退款
export const getServiceIdDetail = (data) => {
    return https.fetchPost('/mallapp/orderrefunds/ApplyForAfterSale', data);
}
//退款/退货退款重新申请
export const refundMoneyAgain = (data) => {
    return https.fetchPost('/mallapp/orderrefunds/reapply', data);
}
//退款/退货退款撤销申请
export const giveUpApply = (data) => {
    return https.fetchPost('/mallapp/orderrefunds/withdrawTheApplication', data);
}
//退款/退货退款撤销申请
export const checkOrderStatus = (params) => {
    return https.fetchGet('/mallapp/orderinfo/checkOrder', params);
}
export const get1688reason = (params) => {
    return https.fetchGet('/mallapp/tripartiteService/queryLogistics', params);
}